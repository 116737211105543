export const Loader = () => {
  return (
    <>
      {/* <main className='loader_bg'>
                <div className="tower">
                    <div className="tower__group">
                        <div className="tower__brick-layer tower__brick-layer--4">
                            <div className="tower__brick tower__brick--0">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--90 tower__brick--red">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--180 tower__brick--orange">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--270 tower__brick--purple">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                        </div>
                        <div className="tower__brick-layer tower__brick-layer--3">
                            <div className="tower__brick tower__brick--45 tower__brick--magenta">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--135">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--225 tower__brick--green">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--315 tower__brick--orange">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                        </div>
                        <div className="tower__brick-layer tower__brick-layer--2">
                            <div className="tower__brick tower__brick--0 tower__brick--red">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--90 tower__brick--green">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--180 tower__brick--purple">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--270">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                        </div>
                        <div className="tower__brick-layer tower__brick-layer--1">
                            <div className="tower__brick tower__brick--45 tower__brick--purple">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--135 tower__brick--magenta">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--225 tower__brick--red">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--315 tower__brick--green">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                        </div>
                        <div className="tower__brick-layer">
                            <div className="tower__brick tower__brick--0 tower__brick--move14">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--90 tower__brick--red tower__brick--move13">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--180 tower__brick--orange tower__brick--move16">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--270 tower__brick--purple tower__brick--move15">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                        </div>
                        <div className="tower__brick-layer tower__brick-layer---1">
                            <div className="tower__brick tower__brick--45 tower__brick--move10 tower__brick--magenta">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--135 tower__brick--move9">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--225 tower__brick--green tower__brick--move12">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--315 tower__brick--orange tower__brick--move11">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                        </div>
                        <div className="tower__brick-layer tower__brick-layer---2">
                            <div className="tower__brick tower__brick--0 tower__brick--red tower__brick--move6">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--90 tower__brick--green tower__brick--move5">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--180 tower__brick--purple tower__brick--move8">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--270 tower__brick--move7">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                        </div>
                        <div className="tower__brick-layer tower__brick-layer---3">
                            <div className="tower__brick tower__brick--45 tower__brick--purple tower__brick--move2">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--135 tower__brick--magenta tower__brick--move1">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--225 tower__brick--red tower__brick--move4">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                            <div className="tower__brick tower__brick--315 tower__brick--green tower__brick--move3">
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-side"></div>
                                <div className="tower__brick-stud"></div>
                                <div className="tower__brick-stud"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="message">
                    <p className="message__line">Assembling one brick at a time…</p>
                    <p className="message__line">Sorting out the pieces…</p>
                    <p className="message__line">Loading…</p>
                    <p className="message__line">Following every step…</p>
                    <p className="message__line">Being very careful not to step on the pieces…</p>
                    <p className="message__line">Trying not to get finger blisters…</p>
                    <p className="message__line">Might take longer to build than the Death Star…</p>
                    <p className="message__line">Wrapping it up…</p>
                </div>
            </main> */}
      {/* <div className='half-circle stroke-alt'></div>  */}

      {/* <div id="loading-animation" className="heading large-text">
        <div
          style={{
            marginLeft: "37vw",
            marginTop: "14rem",
            border: "10px solid purple",
            height: "258px",
            width: "255px",
            borderRadius: "155px",
            padding:'2rem'
          }}
        >
        <p className="j">J</p>
        <p className="u">U</p>
        <p className="s">S</p>
        <p className="p">P</p>
        <p className="l">L</p>
        <p className="a">A</p>
        <p className="y">Y</p>
      </div>
      </div>
      <div id="loading-animation2" className="heading large-text">
        <p className="t">T</p>
        <p className="o">O</p>
        <p className="y">Y</p>
        <p className="s">S</p>
      </div> */}
      {/* <div className='lower'></div> */}
      {/* <div class="container">
        <h1 className="head">
          <span>JusPlay</span>
          <span> Toys</span>
        </h1>

        <div class="blobs_1"></div>
        <div class="blobs_2"></div>
        <div class="blobs_3"></div>
        <div class="blobs_4"></div>
        <div class="blobs_5"></div>
        <div class="blobs_6"></div>
        <div class="blobs_7"></div>
        <div class="blobs_8"></div>
        <div class="blobs_9"></div>
        <div class="blobs_10"></div>
        <div class="blobs_11"></div>
      </div> */}
      <main className='main'>
        <div className='dank-ass-loader'>
          <div className='row'>
            <div className='arrow up outer outer-5'></div>
            <div className='arrow down outer outer-6'></div>
            <div className='arrow up outer outer-7'></div>
            <div className='arrow down outer outer-2'></div>
            <div className='arrow up outer outer-5'></div>
          </div>
          <div className='row'>
            <div className='arrow up outer outer-5'></div>
            <div className='arrow down outer outer-2'></div>
            <div className='arrow up inner outer-6'></div>
            <div className='arrow down inner outer-5'></div>
            <div className='arrow up inner outer-3'></div>
            <div className='arrow down outer outer-2'></div>
            <div className='arrow up outer outer-6'></div>
          </div>
          <div className='row'>
            <div className='arrow down outer outer-3'></div>
            <div className='arrow up outer outer-4'></div>
            <div className='arrow down inner outer-3'></div>
            <div className='arrow up inner outer-2'></div>
            <div className='arrow down inner outer-4'></div>
            <div className='arrow up outer outer-11'></div>
            <div className='arrow down outer outer-10'></div>
          </div>
          <div className='row'>
            <div className='arrow down outer outer-2'></div>
            <div className='arrow up outer outer-6'></div>
            <div className='arrow down outer outer-1'></div>
            <div className='arrow up outer outer-8'></div>
            <div className='arrow down outer outer-4'></div>
          </div>
          <p className='para'>
            <span className='span span-1'>Embrace</span>
            <span className='span span-2'> your </span>
            <span className='span span-3'>rental </span>
            <span className='span span-4'>experience</span>
            <span className='span span-5'>with</span>
            <span className='span span-6'>Jusplay</span>
            <span className='span span-7'>Toys</span>
          </p>
        </div>
      </main>
    </>
  )
}
